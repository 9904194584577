import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";

import * as moment from "moment";
import { environment } from "src/environments/environment";

declare let $: any;
declare let window: any;

@Component({
  templateUrl: "billingdashboard.component.html",
  styleUrls: ["./billingdashboard.component.css"],
})
export class BillingDashboardComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  customerType: string = localStorage.getItem("customerType");
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  accountId: any;
  regionId: any;
  currentMessage: any;
  selectedLinkedAccount: any;

  masterAccounts: any = [];

  loading: any = {
    master: true,
  };
  dateRange: any = "";
  selectedReport: boolean = false;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  async ngOnInit() {
    this.accountId = localStorage.getItem("accountId");
    this.regionId = localStorage.getItem("regionId");

    if (this.customerType !== "mp") {
      setTimeout(() => {
        $("#masterAccountSelect").selectpicker();
      }, 100);
    }
    this.dateRange = moment().startOf("month").format("MM-YYYY");
    $("#datetimepicker").val(moment().startOf("month").format("MM-YYYY"));
    setTimeout(() => {
      $("#datetimepicker")
        .datepicker({
          autoclose: true,
          minViewMode: 1,
          format: "mm-yyyy",
          orientation: "bottom auto",
          endDate: new Date(),
        })
        .on("changeDate", function (selected: any) {
          $(".datetimepicker").val(
            moment(selected["dates"][0]).format("MM-YYYY")
          );
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn();
          });
        });
    }, 100);

    this.currentMessage = this.notifier.currentMessage.subscribe(
      async (msg) => {
        let d = JSON.parse(msg);
        if (d.value == null) {
          return;
        }
        this.masterAccounts = [];
        if (d.key == "accountId") {
          this.accountId = d.value;
          if (this.customerType == "mp") {
            await this.checkForExistingClient();
          }
          this.load(this.customerType !== "mp" ? true : false);
        } else if (d.key == "regionId") {
          this.regionId = d.value;
          this.load(this.customerType !== "mp" ? true : false);
        }
      }
    );

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load(false);
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  services: any = [];
  headers: any = [];
  selectedMasterAccount: any;

  async checkForExistingClient() {
    let data: any = { a: "validate", accountId: this.accountId };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.existingCustomer) {
        this.customerType = "C";
      } else {
        this.customerType = "mp";
      }
    }
  }

  async load(fetch: boolean = true) {
    this.customerType = localStorage.getItem("customerType");
    this.appended = [];
    this.responseData = {};
    this.totalBills = {};

    if (fetch) {
      this.masterAccounts = [];
    }
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        this.headers = [];
        if (fetch) {
          this.loading["master"] = true;
        }

        selectedAccountData.data.forEach(
          async (account: any, index: number) => {
            this.loading[account.accountId] = true;
            this.headers.push({
              id: account.accountId,
              name: `${account.accountName} (${account.accountId})`,
            });
            if (fetch) {
              this.loading["maintotal"] = true;
              await this.fetchMasterAccount(account.accountId);
              if (!this.selectedMasterAccount) {
                this.selectedMasterAccount = this.masterAccounts[0];
              }
            } else {
              ``;
              this.loading["master"] = false;
            }
            this.fetchMonthlyData(
              this.selectedMasterAccount,
              account.accountId
            );
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
    this.loading["maintotal"] = false;
    this.loading["master"] = false;
  }

  toFixed(value: number | string, decimal: number) {
    const num = Number(value);
    return isNaN(num) ? '0.00' : num.toFixed(decimal);
  }

  getSelectedMonthYear() {
    let monthYear = moment().startOf("month").format("YYYY-MM");

    let dRange = $("#datetimepicker").val();
    if (dRange) {
      monthYear = moment(dRange, "MM-YYYY").format("YYYY-MM");
    }

    return monthYear;
  }

  fetchTotalBillService(service: string) {
    let total: number = 0;
    Object.keys(this.responseData).forEach((h: any) => {
      if (this.responseData[h] && this.responseData[h][service]) {
        // Use mrrServiceCost if available, then finalTotalBill, then service-cost
        const cost = this.responseData[h][service]["mrrServiceCost"] || 
                    this.responseData[h][service]["finalTotalBill"] || 
                    this.responseData[h][service]["service-cost"] || 0;
        total += Number(cost);
      }
    });
    return total;
  }

  fetchTotalAccountService(h: string) {
    let total: number = 0;
    if (!this.responseData[h]) {
      return 0.0;
    }
    Object.keys(this.responseData[h]).forEach((service: any) => {
      if (this.responseData[h] && this.responseData[h][service]) {
        if (isNaN(this.responseData[h][service]["finalTotalBill"])) {
          this.responseData[h][service]["finalTotalBill"] = 0;
        }
        total += Number(this.responseData[h][service]["finalTotalBill"]);
      }
    });
    return Number(total.toFixed(2));
  }

  fetchFinalCost() {
    let total: number = 0;
    Object.keys(this.totalBills).forEach((account: any) => {
      //   Object.keys(this.responseData[account]).forEach((h: any) => {
      //     if (isNaN(this.responseData[account][h]["finalTotalBill"])) {
      //       this.responseData[account][h]["finalTotalBill"] = 0;
      //     }
      //     total += this.responseData[account][h]["finalTotalBill"];
      //   });
      total += this.totalBills[account];
    });
    return total.toFixed(2);
  }

  async fetchMasterAccount(account: any = this.accountId) {
    let data = {
      lid: [account],
      userType: "C",
      action: "fetchMasterAccList",
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if(environment.envName == 'Development' && this.urlPrefix == 'admin') {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (account) {
        result.masterAccList = result.masterAccList.filter((account: any) => {
          return this.masterAccounts.indexOf(account) < 0;
        });
        this.masterAccounts = [...this.masterAccounts, ...result.masterAccList];
        setTimeout(async () => {
          $("#masterAccountSelect").selectpicker("refresh");
        }, 100);
      } else {
        this.masterAccounts = result.masterAccList;
      }
    } else {
      this.notifier.alert("Error", "", result.msg, "error", 5000);
    }
    this.loading["master"] = false;
  }

  monthYear: string = null;

  getCurrentMonth() {
    let monthYear: string = moment().startOf("month").format("MM-YYYY");

    let dRange = $("#datetimepicker").val();
    if (dRange) {
      monthYear = dRange;
    }

    return $("#datetimepicker").val();
  }

  async fetchMonthlyData(masterAccId: any = undefined, lid: any) {
    let monthYear: string = moment().startOf("month").format("MM-YYYY");

    let dRange = $("#datetimepicker").val();
    if (dRange) {
      monthYear = dRange;
    }
    this.monthYear = dRange;
    let data: any = {
      masterAccId:
        lid && lid !== "" && this.customerType != "mp"
          ? masterAccId
          : undefined,
      action: this.customerType === "mp" ? "mpbills" : "supportPlanDetails",
      lid: [lid],
      userType: this.customerType || "C",
      month_year: monthYear,
    };
    let header = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
      (lid && lid !== "") || this.customerType === "mp"
        ? "getservicelevelbill"
        : "getbills"
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status === "1" && result.billDetails["items"].length > 0) {
      result.billDetails.items = result.billDetails.items.map(
        (account_: any) => {
          account_.services = account_.services.map((service: any) => {
            if (service["service_cost"]) {
              service["service-cost"] = service["service_cost"];
              service["service-name"] = service["service_name"];
            }
            if (this.urlPrefix == "admin") {
              service["service-name"] = service["service-name"].replaceAll(
                "Amazon ",
                ""
              );
              service["service-name"] = service["service-name"].replaceAll(
                "AWS ",
                ""
              );

              service["service_name"] = service["service_name"].replaceAll(
                "Amazon ",
                ""
              );
              service["service_name"] = service["service_name"].replaceAll(
                "AWS ",
                ""
              );
            }
            service["html"] = `
              <span>${Number(service["service-cost"]).toFixed(4)}</span>`;
            return service;
          });
          return account_;
        }
      );
      if (
        result.appliedSupportPlanInfoList &&
        result.appliedSupportPlanInfoList.length > 0
      ) {
        result.appliedSupportPlanInfoList.forEach((support: any) => {
          support["supportPlanType"] = support["supportPlanType"].replaceAll(
            "Amazon ",
            ""
          );
          support["supportPlanType"] = support["supportPlanType"].replaceAll(
            "AWS ",
            ""
          );
          let service: any = result.billDetails.items[0]["services"].find(
            (service: any) => {
              return (
                support["supportPlanType"]
                  .toLowerCase()
                  .indexOf(service["service-name"].toLowerCase()) > -1
              );
            }
          );
          if (service) {
            service["service-cost"] = support["updatedRate"].toString();
            service["service_cost"] = support["updatedRate"].toString();
          }
        });
      }
      this.parsedData(result, lid);
    }
    this.loading[lid] = false;
  }

  appended: any = [];
  responseData: any = {};
  totalBills: any = {};

  parsedData(responseData: any, lid: any) {
    this.responseData[lid] = {};
    this.totalBills[lid] = Number(responseData["finalTotalCost"].toFixed(2));

    responseData.billDetails["items"][0]["services"].forEach((service: any) => {
      // Calculate initial values
      service["totalBill"] = Number(service["service_cost"] || service["service-cost"]);
      service["name"] = service["service_name"] || service["service-name"];
      service["mrrServiceCost"] = service["mrr_service_cost"] || service["mrrServiceCost"] || null;
      
      // Calculate final bill based on service type
      if (service["name"] === "Amazon Elastic Compute Cloud") {
        service["finalTotalBill"] = service["mrrServiceCost"] || Number(responseData["finalEc2OdTotalCost"]);
      } else if (service["name"] === "Amazon Relational Database Service" || service["name"] == "Relational Database Service") {
        service["finalTotalBill"] = Number(responseData["finalRDSUpdatedCost"]);
      } else if (service["name"].toLowerCase().includes("cloudfront")) {
        service["finalTotalBill"] = service["mrrServiceCost"] || Number(responseData["finalCFRCTotalCost"]) || service["totalBill"];
      } else if (service["name"] === "Amazon Simple Storage Service") {
        service["finalTotalBill"] = service["mrrServiceCost"] || Number(responseData["finalS3TotalCost"]) || service["totalBill"];
      } else {
        service["finalTotalBill"] = service["mrrServiceCost"] || service["totalBill"];
      }

      // Ensure valid numbers and get final value
      service["finalValue"] = Math.min(
        Number(service["totalBill"] || 0),
        Number(service["mrrServiceCost"] || service["finalTotalBill"] || service["totalBill"])
      );

      // Create HTML display with only final value
      service["html"] = `<span>$${this.toFixed(service["finalValue"], 4)}</span>`;

      this.responseData[lid][service.name] = service;
      if (this.appended.indexOf(service.name) < 0) {
        this.appended.push(service.name);
      }
    });
  }

  // helpers

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      "billing-dashboard",
      `Billing Dashboard - ${this.selectedMasterAccount}`,
      "csv"
    );
  }

  close(event: any) {
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load(false);
      },
      component: this,
    };
    this.selectedLinkedAccount = null;
    $("#datetimepicker").val(this.monthYear);
    if (this.customerType !== "mp") {
      $("#masterAccountSelect").selectpicker("refresh");
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem("accountIdData");
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        resolve({ error: false, data: selectedAccounts.list });
      } else {
        reject({ error: true, msg: "No Account is Selected" });
      }
    });
  }
}
