import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as moment from "moment";
import { NotifierService } from "src/app/_services/notifier.service";
import { APIService } from "src/app/api/api.service";

declare let $: any;

@Component({
  selector: "consolidated-ec2-report",
  templateUrl: "./consolidated-ec2-report.component.html",
  styleUrls: ["./consolidated-ec2-report.component.css"],
})
export class ConsolidatedEc2ReportComponent implements OnInit, OnDestroy {
  @Output("hideModel") hideModel: any = new EventEmitter<void>();
  @Input("defaultDate") defaultDate: string;
  @Input('type') type:string;
  @Input('name') name:string;
  @Input('masterAccounts') masterAccounts:any;
  yearMonth: string = "";
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.yearMonth =
      this.defaultDate || moment().startOf("month").format("MM-YYYY");
    $(".datetimepickerclass").val(this.yearMonth);

    setTimeout(() => {
      $(".datetimepickerclass")
        .datepicker({
          autoclose: true,
          minViewMode: 1,
          format: "mm-yyyy",
          orientation: "bottom auto",
          endDate: new Date(),
        })
        .on("changeDate", (selected: any) => {
          $(".datetimepickerclass").val(
            moment(selected["dates"][0]).format("MM-YYYY")
          );
          this.status = null;
          this.link = "";
          this.yearMonth = moment(selected["dates"][0]).format("MM-YYYY");
        });
    }, 100);
  }

  link: string = "";
  loading: boolean = false;
  status: string = null;
  destroyed: boolean = false;

  async requestReport(reGen: boolean = false) {
    let clientEmail: string = this.fetchSelectedClientDetails();
    let accounts: any = localStorage.getItem("accountIdData");
    let accountsObj: any = null;
    if (accounts) {
      accountsObj = JSON.parse(accounts);
      accountsObj = accountsObj.list
        .map((account: any) => {
          return account["accountId"];
        })
        .join(",");
      if (!accountsObj) {
        this.notifier.alert("Info", "", "No accounts found", "info", 5000);
        return;
      }
    } else {
      this.notifier.alert("Info", "", "No accounts found", "info", 5000);
      return;
    }
    this.loading = true;
    this.link = "";
    let data: any = {
      a: "generateEc2",
      lids: accountsObj,
      eid: (clientEmail != 'undefined' && clientEmail) || (localStorage.getItem('masterId') != 'undefined' && localStorage.getItem('masterId')) || localStorage.getItem('eId'),
      monthYear: this.yearMonth,
      reGen: reGen,
    };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/orgreport`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.s == "1" || result.status == "1") {
      this.status = result.msg;
      this.link = result["preUrl"];
      if (!result["preUrl"]) {
        setTimeout(() => {
          this.requestReport(false);
        }, 3000);
      }
      if(result.msg == 'failed'){
        this.notifier.alert(
          "Info",
          "",
          result.error || result.message || result.msg || result.error_message,
          "info",
          5000
        );
      }
    } else {
      this.notifier.alert(
        "Info",
        "",
        result.error || result.message || result.msg || result.error_message,
        "info",
        5000
      );
    }

    this.loading = false;
  }

  async billingAllAccRep(reGen: boolean = false){
    let actionMap: { [key: string]: string } = {
      S3: 'generates3',
      RDS: 'generaterds',
      EC2: 'generateEc2',
      CFRC: 'generatecfrc'
    };
    let selectedAction = actionMap[this.name]; // Get the corresponding action    
      let clientEmail: string = this.fetchSelectedClientDetails();
      let accounts: any = localStorage.getItem("accountIdData");
      let accountsObj: any = null;
      if (accounts) {
        accountsObj = JSON.parse(accounts);
        accountsObj = accountsObj.list
          .map((account: any) => {
            return account["accountId"];
          })
          .join(",");
        if (!accountsObj) {
          this.notifier.alert("Info", "", "No accounts found", "info", 5000);
          return;
        }
      } else {
        this.notifier.alert("Info", "", "No accounts found", "info", 5000);
        return;
      }
      this.loading = true;
      this.link = "";
      let data: any = {
        a: selectedAction, 
        lids: accountsObj,
        eid: (clientEmail != 'undefined' && clientEmail) || (localStorage.getItem('masterId') != 'undefined' && localStorage.getItem('masterId')) || localStorage.getItem('eId'),
        monthYear: this.yearMonth,
        reGen: reGen,
        master: this.masterAccounts,
      };

      let header: any = {
        Authorization: localStorage.getItem("t"),
      };
  
      let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchmasterinfra`;
  
      let result: any = await this.apiServer.postDataPromis(apiURL, data, header);
  
      if (this.destroyed) {
        return;
      }
  
      if (result.s == "1" || result.status == "1") {
        this.status = result.msg;
        this.link = result["preUrl"];
        if (!result["preUrl"]) {
          setTimeout(() => {
            this.billingAllAccRep(false);
          }, 3000);
        }
        if(result.msg == 'failed'){
          this.notifier.alert(
            "Info",
            "",
            result.error || result.message || result.msg || result.error_message,
            "info",
            5000
          );
        }
      } else {
        this.notifier.alert(
          "Info",
          "",
          result.error || result.message || result.msg || result.error_message,
          "info",
          5000
        );
      }
  
      this.loading = false;
  }

  reportcall(){
    if(this.type == 'billing'){
      this.billingAllAccRep(false);
    }
    else{
      this.requestReport(false);
    }
  }

  fetchSelectedClientDetails() {
    let clientList: any = localStorage.getItem("clientList");

    if (clientList && localStorage.getItem("role") == "Admin") {
      let selectedCLient: string = localStorage.getItem("selectedClientList");
      let data: any = JSON.parse(clientList);
      const client = data.find((client: any) => {
        return client["emailId"] == selectedCLient;
      });
      return client["emailId"] || null;
    } else {
      return localStorage.getItem("masterId") || localStorage.getItem("eId");
    }
  }

  downloadReport() {
    var link = document.createElement("a");
    link.download = this.link;
    link.href = this.link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }
}
