<diskdetails-reports *ngIf="reportType == 'diskdetails'" [showHeader]="true"></diskdetails-reports>
<snapshots-backupreport [customHeader]="customHeader" [showHeader]="true"
  *ngIf="reportType == 'dailybackup'" [showHeader]="true"></snapshots-backupreport>
<inventory-reports *ngIf="reportType == 'inventory'"></inventory-reports>
<mis-reports *ngIf="reportType == 'mis'"></mis-reports>
<sg-reports *ngIf="reportType == 'sg'" [sgFilter]="[]" [showHeader]="true" [filterRequired]="false"></sg-reports>
<uptime-reports *ngIf="reportType == 'uptime'"></uptime-reports>
<snapshots-aging-report *ngIf="reportType == 'aging_snapshots'"></snapshots-aging-report>
<app-backup-report *ngIf="reportType == 'backup_reports'"></app-backup-report>
<app-rdsbackup-report *ngIf="reportType == 'rds_backup_reports'"></app-rdsbackup-report>
<reports-inventory-billing *ngIf="reportType == 'inventory-billing'" [showHeader]="true"></reports-inventory-billing>