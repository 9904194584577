<div class="card">
  <div class="card-header display-flex">
    <h5 class="flex-start" *ngIf="!activeAccount && !analytics">
      <button
        class="btn btn-light flex-start"
        (click)="this.hideModel.emit(false)"
        *ngIf="lid"
      >
        <i class="fa fa-angle-left"></i>
        <span>Back</span>
      </button>
      <span>
        Monthly Bill <span *ngIf="lid">- {{ lid }}</span>
      </span>
    </h5>
    <div *ngIf="activeAccount" class="flex-start">
      <button class="btn btn-light" (click)="goBack()">&lt; Back</button>
      <h5 class="flex-start">
        <div>Monthly Bill -</div>
        <div class="flex-start">
          <div>
            {{ activeAccount.lid }}
          </div>
          <div>
            {{ activeAccount.accname ? "(" + activeAccount.accname + ")" : "" }}
          </div>
        </div>
      </h5>
    </div>
  </div>
  <div class="card-body">
    <app-banner-cams
      *ngIf="curMissing && summary.length == 0"
      [accountId]="accountId"
      [regionId]="regionId"
      (hideModal)="curMissing = false"
    ></app-banner-cams>
    <div
      [ngStyle]="{
        'justify-content': diffDays > 0 ? 'space-between' : 'flex-end',
        display: !analytics ? 'flex' : 'none'
      }"
    >
      <div [ngStyle]="{ display: diffDays > 0 ? 'block' : 'none' }">
        <span class="weight-bold">Estimated Usage Details: </span
        ><span>{{ startOf }} - {{ endOf }}</span>
      </div>
      <div class="flex-gap">
        <div
          [ngStyle]="{
            display:
              diffDays > 0 && customerType !== 'mp' && !lid ? 'block' : 'none'
          }"
        >
          <span class="font-bold">Master Accounts</span>
          <span>
            <select
              class="width-300"
              class="form-control"
              id="masterAccountSelect"
              data-live-search="true"
              (change)="fetchMasterAccounts()"
            >
              <option [value]="account" *ngFor="let account of masterAccounts">
                {{ account }}
              </option>
            </select>
          </span>
        </div>
        <div>
          <span class="font-bold">Month</span>
          <span>
            <input
              autocomplete="off"
              class="datetimepicker form-control"
              type="text"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="card"
  [ngStyle]="{
    display:
      !activeAccount && !analytics && !(government_client == masterAccounts)
        ? 'block'
        : 'none'
  }"
>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th
            *ngFor="
              let h of diffDays > 0 ? latestDataSummaryheader : summaryHeader
            "
          >
            {{ h.name }}
          </th>
          <!-- <td>Analytical Report</td> -->
        </tr>
      </thead>

      <tbody
        [ngStyle]="{ display: summary.length > 0 ? 'table-row-group' : 'none' }"
      >
        <tr *ngFor="let i of summary; let j = index">
          <td>{{ j + 1 }}</td>
          <td
            *ngFor="
              let h of diffDays > 0 ? latestDataSummaryheader : summaryHeader
            "
          >
            <span
              *ngIf="h.click"
              class="btn"
              (click)="callFunction(h.click, i)"
            >
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
          <!-- <td>
          <span class="btn btn-light" (click)="showAnalytics(i)">
            Analytical Report
          </span>
        </td> -->
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{
          display: summary.length == 0 ? 'table-row-group' : 'none'
        }"
      >
        <tr>
          <td
            [attr.colspan]="
              (diffDays > 0
                ? latestDataSummaryheader.length
                : summaryHeader.length) + 1
            "
          >
            <div class="align-center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div
  class="card"
  *ngIf="activeAccount && !analytics && !(government_client == masterAccounts)"
>
  <div class="card-header display-flex">
    <div></div>
    <div></div>
    <div class="flex-start">
      <span
        class="mr-10"
        [innerHTML]="'Total: ' + getTotal(lid || accountId) || 0"
      ></span>
      <div>
        <button class="btn btn-success-soft" id="dropdownMenuLinkID">
          Download
          <i class="fa fa-caret-down" aria-hidden="true"></i>
          <div class="dropdownMenuLink">
            <div class="flex-start pl-2">
              <span class="white-space-none"> All Accounts </span>
              <span (click)="preventDefault($event)">
                <label class="switch">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    [(ngModel)]="downloadAll"
                  />
                  <span class="slider round"></span>
                </label>
              </span>
            </div>
            <div class="dropdown-item" (click)="exportPDFTrigger($event)">
              PDF
            </div>
            <div class="dropdown-item" (click)="loadEC2Ddata($event)">CSV</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="billing_summary">
      <div
        class="service"
        (click)="billingOf = 'ec2'"
        [ngClass]="{ 'active-service': billingOf == 'ec2' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/sawyam test icons.png" alt="" />
          <div class="esrvice_name">Elastic Compute Cloud</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['ec2']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["ec2"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData[accountId || lid]['ec2']['discountedCost']">
          <span
            *ngIf="
              servicesData[accountId || lid]['ec2']['service-cost'] >
              servicesData[accountId || lid]['ec2']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["ec2"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["ec2"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['ec2']['service-cost'] <
              servicesData[accountId || lid]['ec2']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["ec2"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["ec2"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 'rds'"
        [ngClass]="{ 'active-service': billingOf == 'rds' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-rds.png" alt="" />
          <div class="esrvice_name">Relational Database Service</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['rds']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["rds"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData[accountId || lid]['rds']['discountedCost']">
          <span
            *ngIf="
              servicesData[accountId || lid]['rds']['service-cost'] >
              servicesData[accountId || lid]['rds']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["rds"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["rds"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['rds']['service-cost'] <
              servicesData[accountId || lid]['rds']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["rds"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["rds"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 'cloudfront'"
        [ngClass]="{ 'active-service': billingOf == 'cloudfront' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-cloudfront.png" alt="" />
          <div class="esrvice_name">CloudFront</div>
        </div>
        <div
          *ngIf="
            !servicesData[accountId || lid]['cloudfront']['discountedCost']
          "
          class="bold_text"
        >
          ${{
            servicesData[accountId || lid]["cloudfront"]["service-cost"] || 0
          }}
        </div>
        <div
          *ngIf="servicesData[accountId || lid]['cloudfront']['discountedCost']"
        >
          <span
            *ngIf="
              servicesData[accountId || lid]['cloudfront']['service-cost'] >
              servicesData[accountId || lid]['cloudfront']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["cloudfront"]["service-cost"] ||
                  0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["cloudfront"][
                  "discountedCost"
                ] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['cloudfront']['service-cost'] <
              servicesData[accountId || lid]['cloudfront']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["cloudfront"][
                  "discountedCost"
                ] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["cloudfront"]["service-cost"] ||
                  0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 's3'"
        [ngClass]="{ 'active-service': billingOf == 's3' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-s3.svg" alt="" />
          <div class="esrvice_name">Simple Storage Service</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['s3']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["s3"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData[accountId || lid]['s3']['discountedCost']">
          <span
            *ngIf="
              servicesData[accountId || lid]['s3']['service-cost'] >
              servicesData[accountId || lid]['s3']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["s3"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["s3"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['s3']['service-cost'] <
              servicesData[accountId || lid]['s3']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["s3"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["s3"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 'support'"
        [ngClass]="{ 'active-service': billingOf == 'support' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-support.png" alt="" />
          <div class="esrvice_name">Support Plans</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['support']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["support"]["service-cost"] || 0 }}
        </div>
        <div
          *ngIf="servicesData[accountId || lid]['support']['discountedCost']"
        >
          <span
            *ngIf="
              servicesData[accountId || lid]['support']['service-cost'] >
              servicesData[accountId || lid]['support']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["support"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["support"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['support']['service-cost'] <
              servicesData[accountId || lid]['support']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["support"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["support"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 'marketplace'"
        [ngClass]="{ 'active-service': billingOf == 'marketplace' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-marketplace.png" alt="" />
          <div class="esrvice_name">Marketplace</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['marketplace']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["marketplace"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData[accountId || lid]['marketplace']['discountedCost']">
          <span
            *ngIf="
              servicesData[accountId || lid]['marketplace']['service-cost'] >
              servicesData[accountId || lid]['marketplace']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["marketplace"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["marketplace"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['marketplace']['service-cost'] <
              servicesData[accountId || lid]['marketplace']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["marketplace"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["marketplace"]["service-cost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['marketplace']['service-cost'] ==
              servicesData[accountId || lid]['marketplace']['discountedCost']
            "
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["marketplace"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
      <div
        class="service"
        (click)="billingOf = 'others'"
        [ngClass]="{ 'active-service': billingOf == 'others' }"
      >
        <div class="service_container">
          <img src="./../../../assets/img/aws-others.png" alt="" />
          <div class="esrvice_name">Others</div>
        </div>
        <div
          *ngIf="!servicesData[accountId || lid]['others']['discountedCost']"
          class="bold_text"
        >
          ${{ servicesData[accountId || lid]["others"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData[accountId || lid]['others']['discountedCost']">
          <span
            *ngIf="
              servicesData[accountId || lid]['others']['service-cost'] >
              servicesData[accountId || lid]['others']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["others"]["service-cost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["others"]["discountedCost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['others']['service-cost'] <
              servicesData[accountId || lid]['others']['discountedCost']
            "
            ><span class="line_through"
              >${{
                servicesData[accountId || lid]["others"]["discountedCost"] || 0
              }}</span
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["others"]["service-cost"] || 0
              }}</span
            ></span
          >
          <span
            *ngIf="
              servicesData[accountId || lid]['others']['service-cost'] ==
              servicesData[accountId || lid]['others']['discountedCost']
            "
            ><span class="bold_text"
              >${{
                servicesData[accountId || lid]["others"]["service-cost"] || 0
              }}</span
            ></span
          >
        </div>
      </div>
    </div>
    <div class="row" *ngIf="billingOf == 'ec2'">
      <ec2-billing
        [activeAccount]="activeAccount"
        [diff_days]="diffDays"
        [monthYear]="dateRange"
        [response]="response[activeAccount.lid]"
        [finalEc2]="
          servicesData[accountId || lid]['ec2']['discountedCost'] >
          servicesData[accountId || lid]['ec2']['service-cost']
            ? servicesData[accountId || lid]['ec2']['service-cost']
            : servicesData[accountId || lid]['ec2']['discountedCost']
        "
      ></ec2-billing>
    </div>
    <div class="row" *ngIf="billingOf == 'rds'">
      <rds-billing
        [activeAccount]="activeAccount"
        [diff_days]="diffDays"
        [monthYear]="dateRange"
      ></rds-billing>
    </div>
    <div class="row" *ngIf="billingOf == 'cloudfront'">
      <cloudfront-billing
        [activeAccount]="activeAccount"
        [diffDays]="diffDays"
        [monthYear]="dateRange"
      >
      </cloudfront-billing>
    </div>
    <div class="row" *ngIf="billingOf == 's3'">
      <s3-billing
        [activeAccount]="activeAccount"
        [diffDays]="diffDays"
        [monthYear]="dateRange"
      >
      </s3-billing>
    </div>
    <div class="row mar-top" *ngIf="billingOf == 'support'">
      <div
        class="right-flex"
        *ngIf="servicesData[lid || accountId].support.services.length > 0"
      >
        <button class="btn btn-light back-color" (click)="exportSupport()">
          Export
        </button>
      </div>
      <table
        class="table table-docs table-hover top-20"
        id="others-table"
        [ngStyle]="{ display: billingOf == 'support' ? 'table' : 'none' }"
      >
        <thead>
          <tr>
            <th>S.N.</th>
            <th>Support Plan</th>
            <th>Cost</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let i of servicesData[lid || accountId].support.services;
              let j = index
            "
            [ngStyle]="{ display: servicesData[lid || accountId].support ? 'table-row' : 'none' }"
          >
            <td>{{ j + 1 }}</td>
            <td>{{ i['service-name'] }}</td>
            <td [innerHTML]="i['html']"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mar-top" *ngIf="billingOf == 'others'">
      <div
        class="right-flex"
        *ngIf="servicesData[lid || accountId].others.services.length > 0"
      >
        <button class="btn btn-light back-color" (click)="export()">
          Export
        </button>
      </div>
      <table
        class="table table-docs table-hover top-20"
        id="others-table"
        [ngStyle]="{ display: billingOf == 'others' ? 'table' : 'none' }"
      >
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of billsHeader">{{ h.name }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let i of servicesData[lid || accountId].others.services;
              let j = index
            "
            [ngStyle]="{ display: getOthers(i) ? 'table-row' : 'none' }"
          >
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of billsHeader">
              <span
                *ngIf="h.clickCheck && h.clickCheck(i[h.id])"
                class="btn"
                (click)="callFunction(h.click, i)"
              >
                <span [innerHTML]="i[h.id] || 0"> </span>
              </span>
              <span *ngIf="h.clickCheck && !h.clickCheck(i[h.id])">
                <span [innerHTML]="i[h.id] || 0"> </span>
              </span>
              <span *ngIf="!h.clickCheck" [innerHTML]="i[h.id] || 0"> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mar-top" *ngIf="billingOf == 'marketplace'">
      <div
        class="right-flex"
        *ngIf="servicesData[lid || accountId].marketplace.services.length > 0"
      >
        <button class="btn btn-light back-color" (click)="exportMarketplace()">
          Export
        </button>
      </div>
      <table
        class="table table-docs table-hover top-20"
        id="others-table"
        [ngStyle]="{ display: billingOf == 'marketplace' ? 'table' : 'none' }"
      >
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of billsHeader">{{ h.name }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let i of servicesData[lid || accountId].marketplace.services;
              let j = index
            "
          >
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of billsHeader">
              <span
                *ngIf="h.clickCheck && h.clickCheck(i[h.id])"
                class="btn"
                (click)="callFunction(h.click, i)"
              >
                <span [innerHTML]="i[h.id] || 0"> </span>
              </span>
              <span *ngIf="h.clickCheck && !h.clickCheck(i[h.id])">
                <span [innerHTML]="i[h.id] || 0"> </span>
              </span>
              <span *ngIf="!h.clickCheck" [innerHTML]="i[h.id] || 0"> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="card" *ngIf="government_client == masterAccounts">
  <div class="card-header display-flex">
    <div></div>
    <div></div>
    <div class="flex-start">
      <span class="mr-10" [innerHTML]="'Total: ' + totalCost || 0"></span>
      
      <div class="flex-gap">
        <button
          class="btn btn-success-soft"
          id="consolidated_rep"
          (click)="download_consolidated()"
        >
        Consolidated Report

        </button>
        <button
          class="btn btn-success-soft"
          id="dropdownMenuLinkID"
          (click)="downloadCSV()"
        >
          Download
          <!-- <i class="fa fa-caret-down" aria-hidden="true"></i> -->
          <!-- <div class="dropdownMenuLink">
            <div class="flex-start pl-2">
              <span class="white-space-none"> All Accounts </span>
              <span (click)="preventDefault($event)">
                <label class="switch">
                  <input autocomplete="off" type="checkbox" [(ngModel)]="downloadAll" />
                  <span class="slider round"></span>
                </label>
              </span>
            </div>
          </div> -->
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of government_headers">
            {{ h.name }}
            <span
              *ngIf="h.sort && h.sort.sort"
              (click)="notifier.sortTable(h, government_data)"
            >
              <i
                class="fa fa-sort-amount-desc"
                aria-hi`dden="true"
                *ngIf="h.sort.direction == 'Des'"
              ></i>
              <i
                class="fa fa-sort-amount-asc"
                aria-hidden="true"
                *ngIf="h.sort.direction == 'Asc'"
              ></i>
            </span>
            <span *ngIf="h.filter" class="inputseach">
              <input
                autocomplete="off"
                type="text"
                [(ngModel)]="h.filterText"
              />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Price Per Service (USD)</th>
          <th>Updated Price Per Service (USD)</th>
          <!-- <th>
           Total Service Cost 
          </th> -->
        </tr>
      </thead>
      <tbody
        *ngFor="let data of government_data; let i = index"
        [ngStyle]="{
          display: government_data.length > 0 ? 'table-row-group' : 'none'
        }"
      >
        <tr *ngIf="notifier.filterTableColums(government_headers, data)">
          <td>{{ i + 1 }}</td>
          <td *ngFor="let h of government_headers">
            <span
              *ngIf="h.click"
              class="btn"
              (click)="callFunction(h.click, data)"
            >
              <span>
                {{ data[h.id] ? data[h.id] : "-" }}
                <!-- Display '-' if value is blank -->
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ data[h.id] ? data[h.id] : "-" }}
              <!-- Display '-' if value is blank -->
            </span>
          </td>
          <td *ngIf="data.totalServiceCost !== null">{{ data.totalServiceCost?.toFixed(4) }}</td>
          <td *ngIf="data.totalServiceCost === null"></td>

          <td *ngIf="data.updatedtotalServiceCost !== null">{{ data.updatedtotalServiceCost?.toFixed(4) }}</td>
          <td *ngIf="data.updaedtotalServiceCost === null"></td>
        </tr>
        <!-- <tr></tr> -->
      </tbody>
      <tbody
        [ngStyle]="{
          display: government_data.length === 0 ? 'table-row-group' : 'none'
        }"
      >
        <tr>
          <td [attr.colspan]="government_headers.length + 1">
            <div class="align-center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<analytics-billing
  *ngIf="analytics"
  [activeAccount]="analytics"
  (hideModal)="hideAnalytics($event)"
>
</analytics-billing>
