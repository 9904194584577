import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { APIService } from "src/app/api/api.service";
import { NotifierService } from "src/app/_services/notifier.service";
import { environment } from "src/environments/environment";

declare let $: any;
declare let window: any;
declare let Jhxlsx: any;

@Component({
  selector: "rds-billing",
  templateUrl: "rds.billing.component.html",
  styleUrls: ["./../../billing.component.css", "./rds.billing.component.css"],
})
export class RDSBillingComponent implements OnInit, OnDestroy, OnChanges {
  @Input("activeAccount") activeAccount: any;
  @Input("monthYear") monthYear: any;
  @Input("diff_days") diff_days: any;

  userId = localStorage.getItem("eId");
  ut = localStorage.getItem("ut");
  urlPrefix: any = localStorage.getItem("role") == "Admin" ? "admin" : "client";
  writeAccess: boolean =
    localStorage.getItem("acT") == "readandwrite" ||
    this.urlPrefix == "admin" ||
    localStorage.getItem("ut") == "admin";
  loading = true;
  loadMore: any;
  customerType: string = localStorage.getItem("customerType");
  showConditionalElement = false;

  rdsData: any = {
    instances: [],
    storage: [],
  };
  rdsHeaders: any = [
    {
      id: "resourceId",
      name: "Resource Name",
      export: true,
    },
    {
      id: "databaseEngine",
      name: "Database Engine",
      export: true,
    },
    {
      id: "region",
      name: "Region",
      export: true,
    },
    {
      id: "availabilityType",
      name: "Availability Type",
      export: true,
      alter: (value: any, param: any) => {
        return value[param] && value[param] != "" ? value[param] : "-";
      },
    },
    {
      id: "riApplied",
      name: "RI Applied",
      export: true,
    },
    {
      id: "riType",
      name: "RI Type",
      export: true,
    },
    {
      id: "riHourlyCost",
      name: "RI Hourly Cost",
      export: true,
      alter: (value: any, param: any) => {
        return !isNaN(value[param]) &&
          value["riApplied"] &&
          value["riApplied"].toLowerCase() == "yes"
          ? Number(value[param]).toFixed(2)
          : "-";
      },
    },
    {
      id: "totalHours",
      name: "Usage",
      export: true,
      alter: (value: any, param: any) => {
        return isNaN(value[param]) ? "-" : Number(value[param]).toFixed(2);
      },
    },
    {
      id: "totalCost",
      name: "Total Cost",
      export: false,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? "-"
          : value["riApplied"] && value["riApplied"].toLowerCase() == "yes"
          ? value["riType"] != "unUsedCustomerRI"
            ? `<span class='line_through'>${Number(value[param]).toFixed(
                2
              )}</span> <span>${Number(value["riTotalCost"]).toFixed(2)}</span>`
            : Number(value["riTotalCost"]).toFixed(2)
          : Number(value[param]).toFixed(2);
      },
    },
    {
      id: "totalCost",
      name: "Total Cost",
      export: true,
      conditionalShow: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? "-"
          : value["riApplied"] && value["riApplied"].toLowerCase() == "yes"
          ? value["riType"] != "unUsedCustomerRI"
            ? Number(value[param]).toFixed(2)
            : Number(value["riTotalCost"]).toFixed(2)
          : Number(value[param]).toFixed(2);
      },
    },
    {
      id: "riTotalCost",
      name: "Final Cost",
      export: true,
      alter: (value: any, param: string) => {
        if (!value[param] || value[param] == "-") {
          return isNaN(value["totalCost"])
            ? "-"
            : Number(value["totalCost"]).toFixed(2);
        } else {
          return isNaN(value[param])
            ? "-"
            : value["riApplied"] && value["riApplied"].toLowerCase() == "yes"
            ? Number(value[param]).toFixed(2)
            : Number(value["totalCost"]).toFixed(2);
        }
      },
    },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    this.customerType = localStorage.getItem("customerType");
    if (changes.activeAccount) {
      if (!this.monthYear || !this.activeAccount) {
        // this.notifier.alert('Error', '', 'Failed to Load Data', 'error', 5000);
      } else {
        this.rdsData = {
          instances: [],
          storage: [],
        };
        this.loadMore = null;
        if (this.customerType == "mp") {
          await this.checkForExistingClient();
        }
        this.loadRDSData();
      }
    }
  }

  async ngOnInit() {}

  async checkForExistingClient() {
    let data: any = { a: "validate", accountId: this.activeAccount.lid };

    let header: any = {
      Authorization: localStorage.getItem("t"),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/billinguser`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == "1" || result.s == "1") {
      if (result.existingCustomer) {
        this.customerType = "C";
      } else {
        this.customerType = "mp";
      }
    }
  }

  async loadRDSData(loadForce: boolean = false) {
    if (!this.loadMore) {
      this.rdsData = {
        instances: [],
        storage: [],
      };
      this.total = {
        instances: 0,
        storage: 0,
      };
    }
    if (!loadForce) {
      this.notifier.loading(true);
    }
    let data = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      userType: this.customerType || "C",
      action: "fetchRds",
      masterAccId: $("#masterAccountSelect")
        ? $("#masterAccountSelect").val()
        : undefined,
      more_instances: this.loadMore ? this.loadMore : undefined,
    };

    let header = {
      Authorization: localStorage.getItem("t"),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    if (this.customerType == "mp") {
      apiURL = `${APIService.API_ENDPOINTV3}/market/billing/subdetails`;
    }

    if (environment.envName == "Development" && this.urlPrefix == "admin") {
      apiURL = `${environment.apiURL}/${this.urlPrefix}/billing/ec2breakup`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if ((result.status == "1" || result.s == "1") && result.rdsDetailsList) {
      let total = {
        instances: this.total.instances || 0,
        storage: this.total.storage || 0,
      };
      result.rdsDetailsList.forEach((rds: any) => {
        let cost: string =
          rds["riTotalCost"] == undefined || rds["riApplied"] == "no"
            ? rds["totalCost"]
            : rds["riTotalCost"];
        if (
          rds["resourceId"].includes("Usage") &&
          rds["resourceId"].includes(":db.")
        ) {
          this.rdsData["instances"].push(rds);
          total["instances"] += Number(cost);
        } else {
          this.rdsData["storage"].push(rds);
          total["storage"] += Number(cost);
        }
      });
      this.total = total;
      // this.rdsData = [...this.rdsData, ...result.rdsDetailsList];
    }

    if (result.more_instances) {
      this.loadMore = result.more_instances;
      if (loadForce) {
        await this.loadRDSData(true);
      }
    } else {
      if (!loadForce) {
        this.notifier.loading(false);
      }
      this.loadMore = null;
    }
    if (!loadForce) {
      this.notifier.loading(false);
    }
  }

  exportLoader: boolean = false;
  view: { [key: string]: boolean } = {
    instances: false,
    storage: false,
  };

  total: { [key: string]: number } = {
    instances: 0,
    storage: 0,
  };
  async export() {
    this.exportLoader = true;
    if (this.loadMore) {
      await this.loadRDSData(true);
    }

    let instances: any = {
      sheetName: "Instances",
      data: [],
    };

    instances["data"].push(
      this.rdsHeaders
        .filter((h: any) => {
          return h.export;
        })
        .map((h: any) => {
          return { text: h["name"] };
        })
    );

    if (this.rdsData["instances"].length > 0) {
      let total: any = {
        totalHours: 0,
        riTotalCost: 0,
      };
      this.rdsData["instances"].forEach((database: any) => {
        instances["data"].push(
          this.rdsHeaders
            .filter((h: any) => {
              return h.export;
            })
            .map((h: any) => {
              if (h.id == "totalHours" || h.id == "riTotalCost") {
                let value = h.alter ? h.alter(database, h.id) : database[h.id];
                value = parseFloat(value);
                total[h.id] += value;
              }
              const total_ = h.alter ? h.alter(database, h.id) : database[h.id];
              return {
                text: isNaN(total_) ? total_ : Number(total_),
              };
            })
        );
      });
      instances["data"].push(
        this.rdsHeaders
          .filter((h: any) => {
            return h.export;
          })
          .map((h: any) => {
            if (h.id == "totalHours" || h.id == "riTotalCost") {
              return {
                text: isNaN(total[h.id]) ? total[h.id] : Number(total[h.id]),
              };
            } else {
              return { text: "" };
            }
          })
      );
    }

    let storages: any = {
      sheetName: "Storages",
      data: [],
    };

    storages["data"].push(
      this.rdsHeaders
        .filter((h: any) => {
          return h.export;
        })
        .map((h: any) => {
          return { text: h["name"] };
        })
    );

    if (this.rdsData["storage"].length > 0) {
      let total: any = {
        totalHours: 0,
        riTotalCost: 0,
      };
      this.rdsData["storage"].forEach((database: any) => {
        storages["data"].push(
          this.rdsHeaders
            .filter((h: any) => {
              return h.export;
            })
            .map((h: any) => {
              if (h.id == "totalHours" || h.id == "riTotalCost") {
                let value = h.alter ? h.alter(database, h.id) : database[h.id];
                value = parseFloat(value);
                total[h.id] += value;
              }
              const total_ = h.alter ? h.alter(database, h.id) : database[h.id];
              return {
                text: isNaN(total_) ? total_ : Number(total_),
              };
            })
        );
      });
      storages["data"].push(
        this.rdsHeaders
          .filter((h: any) => {
            return h.export;
          })
          .map((h: any) => {
            if (h.id == "totalHours" || h.id == "riTotalCost") {
              return {
                text: isNaN(total[h.id]) ? total[h.id] : Number(total[h.id]),
              };
            } else {
              return { text: "" };
            }
          })
      );
    }

    var options = {
      fileName: "rds-bills" + `_${this.activeAccount.lid}`,
    };

    Jhxlsx.export([instances, storages], options);

    this.showConditionalElement = false;
    this.exportLoader = false;
  }

  ngOnDestroy(): void {}
}
